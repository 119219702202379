<template>
  <div class="">
    <center-title title="额度申请/调整申请记录" :bottom="15"></center-title>
    <el-form ref="form" :inline="true" label-width="0px">
      <el-form-item>
        <el-button
          type="primary"
          class="whtie submit"
          @click="(dialogVisible = true), (applyBox.applyType = 'apply')"
        >
          申请额度</el-button
        >
        <el-button
          type="primary"
          class="whtie submit"
          @click="(dialogVisible = true), (applyBox.applyType = 'adjust')"
        >
          调整额度</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="recordLists">
      <el-table-column
        align="center"
        prop="typeText"
        label="申请类型"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="applyNickname"
        label="申请人"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" prop="quota" label="额度" width="120">
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        label="申请时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="auditStatusText"
        label="审批状态"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="earlyAuditManager"
        label="初审人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="earlyAuditReject"
        label="初审意见"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="earlyAuditTime"
        label="初审时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finalAuditManager"
        label="终审人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finalAuditReject"
        label="终审意见"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finalAuditTime"
        label="终审时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="proofAuditManager"
        label="凭证审核人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="proofAuditReject"
        label="凭证审核意见"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="proofAuditTime"
        label="凭证审核时间"
        width="200"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <div
            class="primary cursor mb5"
            v-if="scope.row.isShowPreviewButton"
            @click="preview(scope.row.id)"
          >
            预览
          </div>
          <div
            class="cursor mb5"
            v-if="scope.row.isShowSignButton"
            @click="sign(scope.row.id)"
          >
            签约
          </div>
          <div
            class="primary cursor mb5"
            v-if="scope.row.isShowUploadCertificateButton"
            @click="
              (paymentDialogVisible = true), (paymentBox.applyId = scope.row.id)
            "
          >
            上传支付凭证
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="applyBox.applyType == 'apply' ? '申请额度' : '调整额度'"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-upload
        :action="`${download}/file/formimage`"
        :headers="{ token: $store.getters.userInfo.token }"
        :on-success="successUpload"
        :on-exceed="handleExceed"
        :show-file-list="true"
        :limit="1"
        :on-remove="handleRemove"
      >
        <el-button size="small" type="primary" class="white"
          >上传附件</el-button
        >
      </el-upload>
      <el-upload
        :action="`${download}/file/formOther`"
        :headers="{ token: $store.getters.userInfo.token }"
        :on-success="successUploadContract"
        :on-exceed="handleExceed"
        :show-file-list="true"
        :limit="1"
        :on-remove="handleRemoveContract"
      >
        <el-button
          size="small"
          type="primary"
          class="white"
          :style="{ marginTop: '15px' }"
          >上传合同</el-button
        >
      </el-upload>
      <el-input
        v-model="applyBox.quota"
        type="number"
        placeholder="请输入额度"
        :style="{ marginTop: '15px' }"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setRmOperate()">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="上传支付凭证"
      :visible.sync="paymentDialogVisible"
      width="30%"
    >
      <el-upload
        :action="`${download}/file/formOther`"
        :headers="{ token: $store.getters.userInfo.token }"
        :on-success="successUploadPayment"
        :on-exceed="handleExceed"
        :show-file-list="true"
        :limit="1"
        :on-remove="handleRemovePayment"
      >
        <el-button size="small" type="primary" class="white"
          >点击上传</el-button
        >
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadPayment()">提交</el-button>
      </span>
    </el-dialog>
    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="recordObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      recordLists: [],
      recordObject: {},
      page_no: 1,
      page_size: 10,
      dialogVisible: false,
      paymentDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      applyBox: {
        applyType: "",
        file: "",
        contractFile: "",
        quota: "",
      },
      paymentBox: {
        applyId: "",
        paymentProof: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getApplyQuotaLists() {
      this.$api
        .getApplyQuotaListsApi({
          page_no: this.page_no,
          page_size: this.page_size,
        })
        .then((res) => {
          this.recordLists = res.data.lists;
          this.recordObject = res.data;
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getApplyQuotaLists();
    },
    handleRemove(file, fileList) {
      this.applyBox.file = "";
    },
    handleRemoveContract(file, fileList) {
      this.applyBox.contractFile = "";
    },
    handleRemovePayment(file, fileList) {
      this.paymentBox.paymentProof = "";
    },
    successUpload(res) {
      let { code, data } = res;
      this.applyBox.file = res.data.uri;
    },
    successUploadContract(res) {
      let { code, data } = res;
      this.applyBox.contractFile = res.data.uri;
    },
    successUploadPayment(res) {
      let { code, data } = res;
      this.paymentBox.paymentProof = res.data.uri;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件`);
    },
    setRmOperate() {
      this.$api.applyQuotaApi(this.applyBox).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.resetList(1);
          this.dialogVisible = false;
        }
      });
    },
    uploadPayment() {
      this.$api.uploadCertificateApi(this.paymentBox).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.resetList(1);
          this.paymentDialogVisible = false;
        }
      });
    },
    preview(id) {
      this.$api.getPreviewUrlApi({ applyId: id }).then((res) => {
        if (res.code == 1) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    sign(id) {
      this.$api.getSignUrlApi({ applyId: id }).then((res) => {
        if (res.code == 1) {
          window.open(res.data.url, "_blank");
        }
      });
    },
  },
  created() {
    this.getApplyQuotaLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
